<template>
    <font-awesome-icon
        title="Excluir"
        icon="trash"
        size="lg"
        class="icon-button p-error"
        @click="confirmarExcluir()"
        v-if="$temAcessoView('CHECKINS-CONTAS-EVENTOS-04')"
    />
</template>
<script>
import ContasEventosServices from './services';

export default {
    props: {
        contaEvento: {
            type: Object,
        },
    },

    emits: ['atualizarContasEventos'],

    methods: {
        confirmarExcluir() {
            this.$confirm.require({
                message: `Tem certeza que deseja excluir a conta de evento ${this.contaEvento.nome}? `,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.excluir();
                },
            });
        },

        excluir() {
            this.$store.dispatch('addRequest');
            ContasEventosServices.excluir(this.contaEvento.contaEventoId).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Contas de Eventos',
                        detail: 'Conta de Evento excluída com sucesso',
                        life: 3000,
                    });
                    this.$emit('atualizarContasEventos');
                } else {
                    this.$toast.add({ serverity: 'error', summary: 'Contas de Eventos', detail: response.errors, life: 3000 });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
