<template>
    <Button label="Nova Conta de Evento" icon="pi pi-plus" @click="toInserir()" v-if="$temAcessoView('CHECKINS-CONTAS-EVENTOS-01')" />
</template>

<script>
export default {
    methods: {
        toInserir() {
            this.$router.push({
                name: 'Checkins_ContasEventos_Inserir',
            });
        },
    },
};
</script>
