<template>
    <painel titulo="Contas de Eventos" icone="pi pi-key" :refreshFunction="atualizar" v-if="mostrarListagem">
        <tabela
            :data="contasEventos"
            dataKey="contaEventoId"
            :globalFilterFields="['nome', 'token', 'responsavel', 'email', 'tipoDescr', 'statusDescr']"
            stateKey="dt-checkins-contas-eventos"
        >
            <template #botoes>
                <btn-inserir></btn-inserir>
            </template>
            <template #conteudo>
                <Column headerStyle="width: 10em" header="Ações">
                    <template #body="slotProps">
                        <div class="white-space-nowrap">
                            <btn-atualizar :contaEvento="slotProps.data"></btn-atualizar>
                            <btn-excluir
                                :contaEvento="slotProps.data"
                                class="ml-2"
                                v-if="slotProps.data.permiteExcluir"
                                @atualizarContasEventos="obterContasEventos()"
                            ></btn-excluir>
                        </div>
                    </template>
                </Column>
                <Column field="nome" header="Conta" :sortable="true">
                    <template #body="slotProps">{{ slotProps.data.nome }}</template>
                </Column>
                <Column field="token" header="Token" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.token }}
                    </template>
                </Column>
                <Column field="responsavel" header="Responsável" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.responsavel }}
                    </template>
                </Column>
                <Column field="email" header="E-mail" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.email }}
                    </template>
                </Column>
                <Column field="tipoDescr" header="Tipo" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.tipoDescr }}
                    </template>
                </Column>
                <Column field="status" header="Status" :sortable="true">
                    <template #body="slotProps">
                        <status :status="slotProps.data.statusDescr"> </status>
                    </template>
                </Column>
            </template>
        </tabela>
    </painel>
    <router-view></router-view>
</template>

<script>
import contasEventosServices from './services';
import BtnInserir from './BtnInserir';
import BtnAtualizar from './BtnAtualizar.vue';
import BtnExcluir from './BtnExcluir.vue';

export default {
    components: {
        BtnInserir,
        BtnAtualizar,
        BtnExcluir,
    },
    data() {
        return {
            contasEventos: null,
        };
    },
    methods: {
        obterContasEventos() {
            this.$store.dispatch('addRequest');
            contasEventosServices.obterTodas().then((response) => {
                if (response && response.data) {
                    this.contasEventos = response.data;
                } else {
                    this.contasEventos = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterContasEventos();
        },
    },
    mounted() {
        this.obterContasEventos();
    },

    computed: {
        mostrarListagem() {
            return this.$route.name === 'Checkins_ContasEventos';
        },
    },

    watch: {
        $route(to) {
            if (to.name === 'Checkins_ContasEventos' && this.$store.getters.atualizar) {
                this.obterContasEventos();
                this.$store.dispatch('setAtualizar', false);
            }
        },
    },
};
</script>
