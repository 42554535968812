<template>
    <font-awesome-icon
        title="Atualizar"
        icon="pen"
        size="lg"
        class="icon-button p-text-primary"
        @click="toAtualizar()"
        v-if="$temAcessoView('CHECKINS-CONTAS-EVENTOS-03')"
    />
</template>

<script>
export default {
    props: {
        contaEvento: {
            type: Object,
        },
    },

    methods: {
        toAtualizar() {
            this.$router.push({
                name: 'Checkins_ContasEventos_Atualizar',
                params: {
                    id: this.contaEvento.contaEventoId,
                },
            });
        },
    },
};
</script>